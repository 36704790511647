import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"our-services"} />
		<Helmet>
			<title>
				Services | Наши Услуги на ArtPixel
			</title>
			<meta name={"description"} content={"Разверните полотно вашего творчества – погрузитесь в наш мир графического дизайна"} />
			<meta property={"og:title"} content={"Services | Наши Услуги на ArtPixel"} />
			<meta property={"og:description"} content={"Разверните полотно вашего творчества – погрузитесь в наш мир графического дизайна"} />
			<meta property={"og:image"} content={"https://tovlyswift.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://tovlyswift.com/img/icon1.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://tovlyswift.com/img/icon1.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://tovlyswift.com/img/icon1.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://tovlyswift.com/img/icon1.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://tovlyswift.com/img/icon1.png"} />
			<meta name={"msapplication-TileImage"} content={"https://tovlyswift.com/img/icon1.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Основные Направления Услуг
			</Text>
			<Text
				margin="0px 0px 40px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--darkL1"
				padding="0px 120px 0px 120px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				В ArtPixel мы предлагаем широкий спектр услуг, направленных на развитие ваших навыков в области графического дизайна. От базовых курсов до продвинутых мастер-классов, мы готовы поддержать ваше творческое развитие на каждом этапе пути.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Image
						src="https://tovlyswift.com/img/6.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Категории Курсов
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Основы Дизайна: Идеально подходит для новичков, желающих понять основы цвета, композиции и типографики.
							<br />
							<br />
							Программное Обеспечение для Дизайна: Обучение работы в ведущих графических редакторах, таких как Adobe Photoshop, Illustrator, и другие.
							<br />
							<br />
							Специализированные Направления: Курсы по веб-дизайну, мобильному дизайну, UI/UX, и другие узкоспециализированные темы.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://tovlyswift.com/img/7.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Преимущества ArtPixel
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Практический Опыт: Все наши курсы включают в себя практические задания, которые позволят вам применять полученные знания на практике.
							<br />
							<br />
							Гибкость Обучения: Учащиеся могут проходить курсы в удобном режиме, не выходя из дома.
							<br />
							<br />
							Сообщество: Поддержка и обратная связь от сообщества единомышленников и профессионалов.
							<br />
							<br />
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="230px 0 340px 0"
			background="linear-gradient(0deg,rgba(80, 57, 57, 0.7) 100%,rgba(0,0,0,0) 100%,#5a20ce 100%),#111828 url(https://tovlyswift.com/img/8.jpg) 0% 0%/cover"
			color="#000000"
			sm-padding="80px 0 90px 0"
			md-padding="80px 0 80px 0"
			quarkly-title="Statistics-8"
		>
			<Box min-width="100px" min-height="100px" padding="0px 200px 0px 0px" lg-padding="0px 0 0px 0px">
				<Text margin="0px 0px 0px 0px" color="--light" font="normal 600 42px/1.2 --fontFamily-sans" sm-font="normal 600 36px/1.2 --fontFamily-sans">
				Создайте свою историю вместе с нами
				</Text>
				<Text margin="25px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--light">
				Присоединяйтесь к ArtPixel и позвольте нам помочь вам воплотить ваши самые смелые творческие идеи в жизнь. Наша цель – не просто научить вас основам и техникам дизайна, но и помочь каждому студенту найти свой уникальный стиль и выразить себя через графический дизайн.
				</Text>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});